import { graphql } from "gatsby"
import React from "react"
import Page from "../components/page"
import RequestDemoTemplate from "../components/request-demo-template"
import LogoFullImg from "../img/logo-loadmate-full.svg"
import LogoSmallImg from "../img/logo-loadmate-small.svg"

const RequestDemoLoadmatePage = ({
  data: { footerData, requestDemoLoadmatePageData },
}) => {
  return (
    <Page
      logo={{
        full: LogoFullImg,
        small: LogoSmallImg,
        alt: "Ship.Cars LoadMate",
        link: "/loadmate-solution",
      }}
      headerData={{ frontmatter: {} }}
      footerData={footerData}
    >
      <RequestDemoTemplate data={requestDemoLoadmatePageData.frontmatter} />
    </Page>
  )
}

export default RequestDemoLoadmatePage

export const pageQuery = graphql`
  query RequestDemoLoadmatePage {
    ...PageFragment
    requestDemoLoadmatePageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/request-demo-loadmate.md/" }
    ) {
      frontmatter {
        title
      }
    }
  }
`
